import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const PrivacyAndSecurity = () => {
    const navigate = useNavigate();
    return(
        <div className="w-full flex flex-col gap-10 h-fit">
            <h1 className="text-center text-[3rem] font-bold text-[#8D493A]">Privacy and Security Policy</h1>
            <p className="text-center text-xl italic">Effective as of Nov 12, 2024</p>
            <div className="text-lg space-y-14 px-2 lg:px-16">
                <p className="leading-10">
                    As the owner of this platform, <b>Jeviknows</b>, we understand that your privacy is of critical importance. This privacy policy explains how we collect, uses, discloses and protect information about our users.
                    This policy describes what information we collect from you via the Site. This policy is applied to all users of our website and related services.
                </p>
                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">INFORMATION WE COLLECT</h1>
                <ul className="list-disc space-y-4 w-full">
                    <li className="flex gap-4 leading-10"><MdKeyboardArrowRight className="mt-1" fontSize={24}/>
                        We collect information to provide better services to our users. This includes personal information you provide on our Site such as email Address. We collect your email address during the time of 
                        registration and will be required for authentication whenever the user logs into his/her account.
                    </li>
                    <li className="flex gap-4 leading-10"><MdKeyboardArrowRight className="mt-1"/>
                        This information is also used to provide user with the upcoming updates and events associated 
                        with the platform.
                    </li>
                    <li className="flex gap-4 leading-10"><MdKeyboardArrowRight className="mt-1" fontSize={24}/>
                        There is no automatically collected data as of now but we intend to implement some of the features in the future updates (See more in Future Scopes) to improve user experience and provide a 
                        better environment to anyone visiting the Site. 
                    </li>
                    <li className="flex gap-4 leading-10"><MdKeyboardArrowRight className="mt-1" fontSize={24}/>
                        We don't intend to share your information with any third party. We will not sell or rent your information to any third party. We will not use your information for any other purpose than the one stated
                        above.
                    </li>
                </ul>

                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">OUR USE OF COOKIES</h1>
                <p className="text-justify leading-10">
                    A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to 
                    the server each time the browser requests a page from the server. Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will 
                    remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser 
                    is closed. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and 
                    obtained from cookies. <br/>

                    We use cookies for the following purposes:<br/>
                    (a). <b>authentication</b> - we use cookies to identify you when you visit our website and as you navigate our website;
                    <br/>(b). <b>status</b> - we use cookies to help us determine if you are logged in our website;
                    <br/>(c). <b>personalization</b> - we use cookies to store information about your preferences and settings;
                    <br/>(d). <b>security</b> - we use cookies as an element of the security measures used to protect user information, including preventing fraudulent use of login credentials, and to protect our 
                    website and services generally;
                    <br/>(e). <b>analysis</b> - we use cookies to help us to analyze the use and performance of our website and services;

                    <br/><br/>
                    We use Google Analytics to analyze the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our website is 
                    used to create reports about the use of our website. Google’s privacy policy is available at: <a href="https://www.google.com/policies/privacy/" className="underline font-semibold text-[#8D493A]">https://www.google.com/policies/privacy/</a>
                </p>
                <p className="bg-[#8D493A] text-white p-4 rounded-lg shadow-md">
                    Note: Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. Please note that 
                    blocking cookies may have a negative impact on the functions of many websites, including our Site. Some features of the Site may cease to be available to you.
                </p>

                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">EMAIL ADDRESS</h1>
                <p className="leading-10">
                    We will collect your email address at the time of registration as it is a mandatory information used to identify the user and allow access to the Site. We will use 
                    your email address for the purposes for which you provided it to us (i.e. Login), and also from time to time to send you emails regarding the Site or other products or services that we 
                    believe may be of interest to you. You cannot unsubscribe to such email communications at any time because it is required for the authentication.
                    <br />
                    Your email address may also be shared with the technology provider we use in connection with the purposes for which you provided it.
                </p>
                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">ACCOUNT DATA</h1>
                <p className="leading-10">
                    As you use the Site, we will collect other data that you provide voluntarily (such as posts you like and comments that you post).
                    <br/><br/>
                    1. We may use third parties (i.e. technology provider) to provide the functionality to allow you to register for the Site, in which case the third party will also have access to your information. Otherwise, we will not 
                    provide any personally-identifying information about you to third parties, except if required by law. 
                    <br/>
                    2. We will not use your personally-identifying information (PII) for any reason whatsoever expect for the things stated above.

                    <br/>
                    3. The security of your account is very important to us. We will never ask for your password or any other sensitive information via email. If you receive an email requesting this information, 
                    inform the owner of the site using the email provided in Contact Us section.
                </p>

                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">CONTROL OVER YOUR DATA</h1>
                <p className="leading-10">
                    1. After creating the account on our site, You have full access to modify the information provided to us during the time of user creation.
                    <br/>
                    2. If you encounter any negative experience on our website and do not wish to use the Site in future, you can delete the Account from the Settings Menu. <b>Note: This process is irreversible
                        and all the associated data of the user will be deleted.</b> 
                </p>

                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">SALE OF BUSINESS OR ASSETS</h1>
                <p className="leading-10">
                    In the event that the Site or substantially all of its assets is sold or disposed of as a going concern, whether by merger, sale of assets or otherwise, or in the event of an insolvency, 
                    bankruptcy or receivership, the information we have collected about you may be one of the assets sold or merged in connection with that transaction.
                </p>

                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">RULES REGARDING PUBLISHING OBSCENE AND HATEFUL CONTENT</h1>
                <p className="leading-10">
                    1. No person shall post anything which is vulgar or nude in nature. <br/>
                    2. Publishing anything towards Religion or any type of Hate Speech is not allowed and their post will be taken down by the admins.<br/>
                    3. Anything which is illegal in the eyes of law will be applied. 
                </p>
                <p className='font-bold'>
                    Jeviknows does not promote any type of Obscenity, Nudity and Hateful Speech.
                </p>

                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">CHANGES TO THE PRIVACY POLICY</h1>
                <p className="leading-10">
                    We may change this Privacy Policy from time to time. The most recent version of the Privacy Policy will always be posted on the Site, with the “Effective Date” posted at the top of the 
                    Policy. We may revise and update this Privacy Policy if our practices change, as technology changes, or as we add new services or change existing ones. If we make any material changes 
                    to our Privacy Policy or how we handle your personal information, or we are going to use any personal information in a manner that is materially different from that stated in our Privacy 
                    Policy at the time we collected such information, we will give you a reasonable opportunity to consent to the change. If you do not consent, your personal information will be used as 
                    agreed to under the terms of the privacy policy in effect at the time we obtained that information. By using our Site or services after the Effective Date, you are deemed to consent to 
                    our then-current privacy policy. We will use information previously obtained in accordance with the Privacy Policy in effect when the information was obtained from you.
                </p>

                <h1 className="text-center text-4xl uppercase font-semibold overflow-hidden">CONTACTING US</h1>
                <p className="leading-10">
                    If you have any questions about this Privacy Policy, or the practices of this Site, please contact us via our <a onClick={() => navigate("/contact")} className="underline text-[#8D493A] cursor-pointer">contact page</a>.
                </p>
            </div>
        </div>
    )
};

export default PrivacyAndSecurity;