import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../../Spinner";
import RenderSteps from "../../Dashboard/AddPost/RenderSteps";
import { getFullDetailsOfPost } from "../../../../services/operations/postDetailsAPI";
import { setEditPost, setPost } from "../../../../slices/postSlice";
import Card from "../../../common/Card Component/Card";

export default function EditPost(){

    const dispatch = useDispatch();
    const { postId } = useParams();
    const { post } = useSelector((state) => state.post);
    const [loading, setLoading] = useState(false);
    const { token } = useSelector((state) => state.auth);

    useEffect(() => {
        const populatePostDetails = async () => {
            setLoading(true);
            const result = await getFullDetailsOfPost(postId, token);
            if(result){
                dispatch(setEditPost(true));
                dispatch(setPost(result));
            }
            setLoading(false);
        }
        populatePostDetails();
    }, [])

    if(loading){
        return (
            <div>
                <Spinner />
            </div>
        )
    }

    return(
        <div className="px-8 py-8 lg:px-[6rem] lg:py-[5rem] overflow-y-auto bg-white flex flex-col-reverse lg:flex-row gap-10 w-full justify-center flex-1 min-h-[790px] items-center lg:items-start">
            <div className="w-full lg:w-8/12">
                <h1 className="text-4xl  font-bold overflow-hidden">Edit Post</h1>
                <div>
                    {
                        post ? (<RenderSteps />) : (<p>Post Not Found</p>)
                    }
                </div>
            </div>
            <div className="w-full lg:w-4/12 2xl:p-10">
                <Card text={"post"}/>
            </div>
        </div>
    )
}