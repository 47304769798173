import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { createSubSection, updateSubSection } from '../../../../../services/operations/postDetailsAPI';
import { setPost } from '../../../../../slices/postSlice';
import { RxCross2 } from "react-icons/rx";
import IconBtn from '../../../../common/IconBtn';
import Upload from './Upload';
import { Editor } from '@tinymce/tinymce-react';

const SubSectionModal = ({
    modalData,
    setModalData,
    add = false,
    edit = false,
    view = false,
}) => {

    const [description, setDescription] = useState(modalData?.description || "");

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        getValues
    } = useForm();

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { post } = useSelector((state) => state.post);
    const { token } = useSelector((state) => state.auth);

    useEffect(() => {
        if (view || edit) {
            setValue("postTitle", modalData?.title);
            //setValue("postDesc", modalData?.description);
            setDescription(modalData?.description);
            //setValue("postImage", modalData?.imageUrl?.url);
            if(modalData?.imageUrl){
                setValue("postImage", modalData?.imageUrl);
            } else {
                setValue("postImage", null); //explicitly set to null if no image
            }
        }
    }, [view, edit, modalData, setValue]);

    const isFormUpdated = () => {
        const currentValues = getValues();
        return currentValues.postTitle !== modalData?.title || 
               //currentValues.postDesc !== modalData?.description ||
                description !== modalData?.description ||
                //currentValues.postImage !== modalData?.imageUrl?.url;
                (currentValues.postImage !== null && currentValues.postImage !== modalData?.imageUrl);
    }

    const handleEditSubSection = async () => {
        const currentValues = getValues();
        const formData = new FormData();
        
        formData.append("sectionId", modalData.sectionId);
        formData.append("subSectionId", modalData._id);
        
        if (currentValues.postTitle !== modalData.title) {
            formData.append("title", currentValues.postTitle);
        }

        // if (currentValues.postDesc !== modalData.description) {
        //     formData.append("description", currentValues.postDesc);
        // }

        if (description !== modalData.description) {
            formData.append("description", description);
        }

        // Append image only if it exists and is different from the existing image
        if (currentValues.postImage && currentValues.postImage !== modalData?.imageUrl) {
            formData.append("imageFile", currentValues.postImage);
        }

        setLoading(true);
        
        const result = await updateSubSection(formData, token);

        if (result) {
            const updatedPostContent = post.postContent.map((section) => 
                section._id === modalData.sectionId ? result : section);
            const updatedPost = { ...post, postContent: updatedPostContent };
            dispatch(setPost(updatedPost));
        }

        setModalData(null);
        setLoading(false);
    }

    const onSubmit = async (data) => {
        if (view) return;

        if (edit) {
            if (!isFormUpdated()) {
                toast.error("No changes made");
            } else {
                handleEditSubSection();
            }
            return;
        }

        const formData = new FormData();
        formData.append("sectionId", modalData);
        formData.append("title", data.postTitle);
        //formData.append("description", data.postDesc);
        formData.append("description", description);
        // formData.append("imageFile", data.postImage);
        
        // Append image only if it exists
        if (data.postImage) {
            formData.append("imageFile", data.postImage);
        }
        setLoading(true);

        const result = await createSubSection(formData, token);

        if (result) {
            const updatedPostContent = post.postContent.map((section) => 
                section._id === modalData ? result : section);
            const updatedPost = { ...post, postContent: updatedPostContent };
            dispatch(setPost(updatedPost));
        }
        setModalData(null);
        setLoading(false);
    }
    
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50 px-5 sm:px-0">
            <div className="bg-[#F8EDE3] rounded-lg shadow-lg md:mx-4 xl:max-w-[1070px] w-full h-[540px] lg:h-[450px]">
                <div className="flex justify-between items-center mb-4 bg-[#D0B8A8] px-6 py-4">
                    <p className="text-lg font-semibold">{view ? "Viewing" : add ? "Adding" : "Editing"} Sub-Section</p>
                    <button onClick={() => (!loading ? setModalData(null) : {})}
                        className="bg-red-600 hover:bg-red-700 p-2 rounded-full text-white transition-all duration-300">
                        <RxCross2 fontSize={20} />
                    </button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="px-6 flex flex-col lg:flex-row gap-4 w-full">
                    <div className="w-full lg:w-5/12">
                        <Upload 
                            name="postImage"
                            label="Post Image"
                            register={register}
                            setValue={setValue}
                            errors={errors}
                            image={true}
                            viewData={view ? modalData?.imageUrl || null : null}
                            editData={edit ? modalData?.imageUrl || null : null}
                        />
                        <div className="mb-4">
                            <label htmlFor='postTitle' className="block text-sm font-semibold text-gray-700">Sub-Section Title</label>
                            <input 
                                disabled={view}
                                id='postTitle'
                                placeholder='Enter Title'
                                {...register("postTitle", { required: true })}
                                className='w-full rounded-md p-2 focus:outline-none bg-white text-black'
                            />
                            {errors.postTitle && <span className='text-red-500 text-sm'>This field is required</span>}
                        </div>
                    </div>

                    <div className="w-full lg:w-6/12">
                        <div className="mb-4">
                            <label htmlFor='postDesc' className="block text-sm font-semibold text-gray-700">Sub-Section Description</label>
                            {/* <textarea 
                                id='postDesc'
                                placeholder='Enter Description'
                                {...register("postDesc", { required: true })}
                                className='w-full rounded-md p-2 focus:outline-none bg-white text-black resize-none min-h-[140px]'
                            /> */}
                            <Editor 
                                apiKey="xtjxo5g21xvjm08gufe42paufre8jni83ss2ndhutbr2gyc8"
                                value={description}
                                onEditorChange={(newDescription) => setDescription(newDescription)}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: 'lists link',
                                    toolbar: 'undo redo bold italic underline alignjustify | bullist numlist | link',
                                }}
                                disabled={view}
                            />
                            {errors.postDesc && <span className='text-red-500 text-sm'>This field is required</span>}
                        </div>
                    </div>

                    {!view && (
                        <div className="mb-4">
                            <IconBtn 
                                text={loading ? "Loading..." : edit ? "Save Changes" : "Save"}
                                customClasses={"px-6 py-3 bg-blue-600 hover:bg-blue-700 transition-all duration-300 rounded-lg text-white flex items-center gap-2"}
                            />
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default SubSectionModal;
