import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import '../index.css'
import footer from '../assets/footer-bg.jpg';
import logo from '../assets/Logo Circle - PNG.png';
import logotext from '../assets/logo-text.png';
import Footer from "../components/common/Footer";
import { formatDate } from "../services/formatDate";
import { Link } from "react-router-dom";
import { apiConnector } from "../services/apiconnector";
import { blogDetailsEndpoints } from "../services/apis";
import EmptyLoader from "../components/EmptyLoader";
import { IoIosArrowForward } from "react-icons/io";
import { getAdminPostDetails } from "../services/operations/blogDetailsAPI";
import CommentSlider from "../components/core/Home/CommentSlider";
import cat from '../assets/cat.png';
import welcometext from "../assets/welcm.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

    const Home = () => {

        const [allPosts, setAllPosts] = useState([]);
        const [adminPosts, setAdminPosts] = useState([]);

        const truncateText = (text = '', wordLimit) => {
            const words = text.split(' ');
            return words.length > wordLimit ? `${words.slice(0, wordLimit).join(' ')}...` : text;
          };

        const { GET_USER_POST_DETAILS_API, GET_ADMIN_POST_DETAILS_API } = blogDetailsEndpoints;

        useEffect(() => {
            const fetchAllPosts = async () => {
                try {
                    const [userResponse, adminResponse] = await Promise.all([
                        apiConnector("GET", GET_USER_POST_DETAILS_API),
                        apiConnector("GET", GET_ADMIN_POST_DETAILS_API)
                    ]);
    
                    // Combine both posts into a single array
                    const combinedPosts = [
                        ...userResponse.data.data,
                        ...adminResponse.data.data
                    ];
    
                    // Sort the combined posts by date (assuming `createdAt` is the date field)
                    combinedPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
                    // Set the combined and sorted posts
                    setAllPosts(combinedPosts);
                } catch (error) {
                    console.log(error);
                }
            };

            const fetchAdminPosts = async () => {
                try{
                    const response = await getAdminPostDetails();
                    setAdminPosts(response);
                }
                catch(error){
                    console.log(error);
                }
            }

            fetchAdminPosts();
            fetchAllPosts();
        }, []);

        const publishedAdminPosts = adminPosts.filter((post) => post.status === "Published");
        const publishedAllPosts = allPosts.filter((post) => post.status === "Published");
        
        return(
            <div className="w-screen h-auto flex flex-col justify-center">
                {/* Section - 1 */}
                <div className="w-full px-4 max-w-[480px] sm:max-w-[800px] lg:max-w-[800px] xl:max-w-[1000px] 2xl:max-w-[1500px] mx-auto my-5 flex flex-col">
                    <div className="w-full gap-[2%] space-y-5 lg:space-y-0 xl:space-y-0 2xl:space-y-0 h-full flex flex-col lg:flex-row 2xl:flex-row">
                        <div className="w-full lg:w-[65%] 2xl:w-[65%] flex flex-col p-5 bg-white rounded-xl h-fit">
                           <div className="w-full flex items-center justify-center h-[65%] overflow-hidden">
                                {/* admin post */}
                                {
                                        publishedAdminPosts?.length === 0 ? (
                                            <div className="flex justify-center items-center w-full">
                                                <Spinner />
                                            </div>
                                        ) : (
                                            <Link to={`/posts/${publishedAdminPosts[0]?._id}`} className="flex gap-5 group flex-col sm:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
                                                <div className="w-full sm:w-[50%] lg:w-[50%] xl:w-[50%] 2xl:w-[50%] my-auto">
                                                    <img src={publishedAdminPosts[0]?.thumbnail?.url} alt={publishedAdminPosts[0]?.postName} loading="lazy"
                                                    className="w-full aspect-square object-cover rounded-xl"/>
                                                </div>

                                                <div className="flex gap-3 flex-col my-auto w-full sm:w-[50%] sm:p-5 lg:w-[50%] lg:p-5 xl:w-[50%] xl:p-5 2xl:w-[50%] 2xl:p-5 p-0">
                                                    <div className="px-6 py-2 bg-red-600 rounded-lg text-white w-fit">Trending</div>

                                                    <div className="space-y-2">
                                                        <p className="text-[2rem] sm:text-[2.5rem] lg:text-[2.5rem] xl:text-[2.5rem] 2xl:text-[2.5rem] font-extrabold uppercase group-hover:text-blue-600
                                                        transition-all duration-300">
                                                            {truncateText(publishedAdminPosts[0]?.postName, 20)}...
                                                        </p>

                                                        <p className="px-5 border-2 rounded-full border-slate-300 py-2 w-fit 
                                                        group-hover:border-black transition-all duration-300">
                                                            {publishedAdminPosts[0]?.category?.name}
                                                        </p>
                                                    </div>

                                                    <div className="flex items-center gap-4">
                                                        <p className="flex gap-3 text-md items-center">
                                                            <img src={publishedAdminPosts[0]?.user?.image?.url} alt="user" 
                                                            className="w-[40px] aspect-square rounded-full bg-white"/>
                                                            {publishedAdminPosts[0]?.user?.firstName} 
                                                        </p>
                                                        <div className="h-7 w-[2px] bg-slate-300"></div>
                                                        <p>{formatDate(publishedAdminPosts[0]?.createdAt)}</p>
                                                    </div>
                                                </div>

                                            </Link>
                                        )
                                }
                           </div>

                           <div className="w-full mt-4 h-[35%] overflow-hidden">
                                {/* comments */}
                                <h1 className="bg-red-600 text-white font-semibold px-5 py-2 rounded-full w-fit">Trending Discussions</h1>
                                <CommentSlider />
                           </div>

                           <div className="w-fit mx-auto overflow-hidden group px-10 py-4 rounded-xl bg-[#F8EDE3]">
                                <Link to={"/stories"} className="flex gap-2 items-center group-hover:font-bold transition-all duration-300">Check out latest Stories!  <MdOutlineKeyboardArrowRight /></Link>
                           </div>
                        </div>

                        <div className="w-full lg:w-[33%] 2xl:w-[33%] flex flex-col items-center h-full rounded-xl space-y-2 overflow-hidden">
                            <div className="flex gap-2 h-[38%] w-full items-center justify-center bg-[#d0b8a860] rounded-xl backdrop-blur-sm">
                                <img src={cat} alt="welcat" className="w-[30%] h-[30%]"/>
                                <img src={welcometext} alt="welcome" className="w-[50%] h-[50%]"/>
                            </div>

                            <div className="w-full flex flex-col items-center">
                                <h1 className="bg-red-600 text-white font-semibold px-5 py-2 rounded-full w-fit items-start mb-2">Latest Posts</h1>
                                {
                                    publishedAllPosts?.length === 0 ? (
                                        <div className="flex justify-center items-center w-full">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <>
                                        <div className="flex flex-col justify-center items-center space-y-3">
                                            {
                                                publishedAllPosts?.slice(0, 2).map((post, index) => {
                                                    return(
                                                        <Link to={`/posts/${post?._id}`} className="flex flex-row lg:flex-col xl:flex-col 2xl:flex-col items-center gap-5 group w-full" key={index}>
                                                            <div className="w-[40%] lg:w-full xl:w-full 2xl:w-full">
                                                                <img src={post?.thumbnail?.url} alt={post?.postName} loading="lazy"
                                                                className="w-full aspect-square lg:aspect-video xl:aspect-video 2xl:aspect-video object-cover rounded-xl"/>
                                                            </div>
        
                                                            <div className="flex gap-3 flex-col w-[60%] lg:w-full xl:w-full 2xl:w-full">
                                                                <div className="space-y-2">
                                                                    <p className="text-md sm:text-xl lg:text-md xl:text-md 2xl:text-md font-extrabold uppercase group-hover:text-blue-600
                                                                    transition-all duration-300">
                                                                        {truncateText(post?.postName, 5)}...
                                                                    </p>
        
                                                                    <p className="w-fit space-x-3 xl:flex xl:items-center 2xl:flex 2xl:items-center">
                                                                        <p className="px-2 border-2 text-sm rounded-full border-slate-300 py-1
                                                                        group-hover:border-black transition-all duration-300">{post?.category?.name}</p>
                                                                        {/* Optional Condition */}
                                                                        <span className="hidden xl:block 2xl:block">
                                                                            <div className="flex items-center gap-2 xl:hidden 2xl:hidden">
                                                                                <p className="flex gap-2 text-sm items-center">
                                                                                    <img src={post?.user?.image?.url} alt="user"
                                                                                    className="w-[20px] aspect-square rounded-full bg-white"/>
                                                                                    {post?.user?.firstName}
                                                                                    
                                                                                </p>
                                                                                <div className="h-7 w-[2px] bg-slate-300"></div>
                                                                                <p className="text-sm">{formatDate(post?.createdAt)}</p>
                                                                            </div>
                                                                        </span>
                                                                    </p>
                                                                </div>
        
                                                                <div className="flex items-center gap-2 xl:hidden 2xl:hidden">
                                                                    <p className="flex gap-2 text-sm items-center">
                                                                        <img src={post?.user?.image?.url} alt="user"
                                                                        className="w-[20px] aspect-square rounded-full bg-white"/>
                                                                        {post?.user?.firstName}
                                                                        
                                                                    </p>
                                                                    <div className="h-7 w-[2px] bg-slate-300"></div>
                                                                    <p className="text-sm">{formatDate(post?.createdAt)}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>

                                        <div className="text-center mt-4">
                                            <Link className="w-fit flex gap-3 items-center px-6 rounded-full py-2 text-white 
                                            bg-[#8D493A] hover:bg-[#75392c] transition-all duration-300"
                                            to="/catalog/self-improvement">
                                                <p>See More</p>
                                                <IoIosArrowForward />
                                            </Link>
                                        </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>  
                </div>

                {/* Section - 2 */}
                <Footer footer={footer} logo={logo} logotext={logotext}/>

            </div>
        )
    }

    export default Home;