import React, { useState, useEffect } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import { HiUpload } from "react-icons/hi";

const Upload = ({ name, label, register, setValue, errors, viewData, editData }) => {
    const [imagePreview, setImagePreview] = useState(null);
    const [imageName, setImageName] = useState("");

    useEffect(() => {
        if (viewData || editData) {
            setImagePreview(viewData || editData);
            setImageName("Existing Image");
        }
    }, [viewData, editData]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl); // Set the preview for the newly uploaded image
            setImageName(file.name);
            setValue(name, file); // Set the file in the form state
        }
    };

    const handleImageRemove = () => {
        setImagePreview(null);
        setImageName("");
        setValue(name, null); // Clear the file from the form state
    };

    return (
        <div className="mb-4">
            <label htmlFor={name} className="block mb-2 font-semibold">
                {label}
            </label>
            <div className="flex items-center justify-around border-2 border-dashed border-slate-400 bg-white rounded-md p-4 h-auto relative">
                {imagePreview ? (
                    <>
                        <img src={imagePreview} alt="Thumbnail" className="object-cover aspect-video w-full h-full rounded-md" />
                        <button
                            type="button"
                            className="absolute top-5 right-5 p-1 text-red-500 bg-white rounded-full"
                            onClick={handleImageRemove}
                        >
                            <AiOutlineDelete fontSize={20} />
                        </button>
                        <span className="absolute bottom-2 left-2 text-gray-800 bg-white p-1 rounded-md">
                            {imageName}
                        </span>
                    </>
                ) : (
                    <div className="flex items-center gap-4">
                        <span className="text-gray-500 text-sm">Upload a photo as the thumbnail (If required)</span>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                            id={name}
                            //{...register(name)} // Register the input (optional validation can be added here)
                        />
                        <label
                            htmlFor={name}
                            className="font-medium text-black bg-gray-200 p-2 sm:p-2 flex justify-center items-center rounded-full focus:outline-none cursor-pointer"
                        >
                            <HiUpload fontSize={22}/>
                        </label>
                    </div>
                )}
            </div>
            {/* Conditionally render the error message if there's a validation error */}
            {errors[name] && <span className="text-red-500">{errors[name].message}</span>}
            <p className="text-gray-500 text-sm mt-1">PNG/JPG Supported</p>
        </div>
    );
};

export default Upload;